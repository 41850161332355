import { jsx as _jsx } from "react/jsx-runtime";
import { createStyles, makeStyles } from "@mui/styles";
import { ButtonBase } from "@mui/material";
import { Colors } from "../colors";
export const OutlinedButton = ({ children, disabled, style = {}, ...nestedProps }) => {
    const styles = useStyles();
    return (_jsx(ButtonBase, { className: `outlined-button ${styles.button} ${disabled ? 'disabled' : ''}`, style: {
            padding: '.5rem',
            border: 'solid 1px',
            borderColor: disabled ? Colors.appBorderColor : Colors.textWhite,
            borderRadius: '.8rem',
            animation: "fade 250ms ease-in-out",
            ...style
        }, ...nestedProps, children: children }));
};
const useStyles = makeStyles((_theme) => createStyles({
    button: {
        '&:hover': {
            backgroundColor: Colors.appActiveBgColor,
        },
    },
}));
