import { BaseStore, makeObservable, observable } from "@core-ui/react-mobx-state";
export class PermissionStore extends BaseStore {
    error = null;
    permissions;
    permission;
    filterData;
    loginData = {};
    loading = false;
    constructor() {
        super();
        makeObservable(this, {
            error: observable,
            permissions: observable,
            loading: observable,
            loginData: observable,
            permission: observable,
            filterData: observable,
        });
    }
}
