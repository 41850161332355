export const camelToSnakeCase = (str) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};
export function convertToSnakeCase(obj) {
    if (Array.isArray(obj)) {
        return obj.filter(Boolean).map((o) => convertToSnakeCase(o));
    }
    if (typeof obj !== "object" || obj === null) {
        return obj === null ? undefined : obj;
    }
    const snakeCaseObj = {};
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === "object") {
            snakeCaseObj[key.toSnakeCase()] = convertToSnakeCase(value);
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            snakeCaseObj[key.toSnakeCase()] = value;
        }
    }
    return snakeCaseObj;
}
export const snakeToCamelCase = (str) => {
    return str.replace(/(_\w)/g, (letter) => (letter[1] || "").toUpperCase());
};
export const convertToCamelCase = (obj) => {
    const camelCaseObj = {};
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === "object" && value !== null) {
            camelCaseObj[key.toCamelCase()] = convertToCamelCase(value);
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            camelCaseObj[key.toCamelCase()] = value;
        }
    }
    return camelCaseObj;
};
export const camelToPascalCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
String.prototype.toSnakeCase = function () {
    return camelToSnakeCase(this.valueOf());
};
String.prototype.toCamelCase = function () {
    return snakeToCamelCase(this.valueOf());
};
