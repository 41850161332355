import { BaseStore, makeObservable, observable } from "@core-ui/react-mobx-state";
export class AuthStore extends BaseStore {
    error = null;
    authData;
    loginData = {};
    registerData = {};
    resetPasswordData;
    loading = false;
    wasFinishedValidate = false;
    constructor() {
        super();
        makeObservable(this, {
            error: observable,
            authData: observable,
            loading: observable,
            loginData: observable,
            registerData: observable,
            resetPasswordData: observable,
            wasFinishedValidate: observable,
        });
    }
}
