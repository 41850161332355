import { BaseService } from "@core-ui/api-client";
export class CommentService extends BaseService {
    constructor(api) {
        super(api);
    }
    create(createData) {
        return this.api.post("/comments", {}, createData);
    }
    update(id, updateData) {
        return this.api.put("/comments/{commentId}", { commentId: id }, updateData);
    }
    patchUpdate(commentId, updateData) {
        return this.api.patch("/comments/{commentId}", { commentId }, updateData);
    }
    getOne(id) {
        return this.api.get("/comments/{commentId}", { commentId: id });
    }
    getMany(filter) {
        return this.api.get("/comments", filter);
    }
    getAll(filter) {
        return this.api.get("/comments/all", filter);
    }
    delete(id) {
        return this.api.delete("/comments/{commentId}", { commentId: id });
    }
}
