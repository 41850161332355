import { BaseService } from "@core-ui/api-client";
export class NovelService extends BaseService {
    constructor(api) {
        super(api);
    }
    create(createData) {
        return this.api.post("/novels", {}, createData);
    }
    update(id, updateData) {
        return this.api.put("/novels/{novelId}", { novelId: id }, updateData);
    }
    patchUpdate(novelId, updateData) {
        return this.api.patch("/novels/{novelId}", { novelId }, updateData);
    }
    getOne(id) {
        return this.api.get("/novels/{novelId}", { novelId: id });
    }
    getMany(filter) {
        return this.api.get("/novels", filter);
    }
    getAll(filter) {
        return this.api.get("/novels/all", filter || {});
    }
    delete(id) {
        return this.api.delete("/novels/{novelId}", { novelId: id });
    }
}
