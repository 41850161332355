import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./smoke.style.css";
export const Smoke = ({ id, wind = 0, blur = 2, style = {} }) => {
    return (_jsxs("div", { className: 'smoke', id: `smoke-${id}`, style: {
            "--wind": `${wind}`,
            "--blur": `${blur}`,
            pointerEvents: "none",
            ...style
        }, children: [_jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' }), _jsx("div", { className: 'smoke-particle' })] }));
};
