import { BaseService } from "@core-ui/api-client";
export class AuthorService extends BaseService {
    constructor(api) {
        super(api);
    }
    create(createData) {
        return this.api.post("/authors", {}, createData);
    }
    update(id, updateData) {
        return this.api.put("/authors/{authorId}", { authorId: id }, updateData);
    }
    patchUpdate(authorId, updateData) {
        return this.api.patch("/authors/{authorId}", { authorId }, updateData);
    }
    getOne(id) {
        return this.api.get("/authors/{authorId}", { authorId: id });
    }
    getMany(filter) {
        return this.api.get("/authors", filter);
    }
    getAll(filter) {
        return this.api.get("/authors/all", filter || {});
    }
    delete(id) {
        return this.api.delete("/authors/{authorId}", { authorId: id });
    }
}
