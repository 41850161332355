import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import "./ex1.style.css";
gsap.registerPlugin(useGSAP);
export default function Boxes() {
    const container = useRef(null);
    const tl = useRef(null);
    const toggleTimeline = () => {
        if (tl.current) {
            tl.current.reversed(!tl.current.reversed());
        }
    };
    useGSAP(() => {
        const boxes = gsap.utils.toArray('.box');
        tl.current = gsap
            .timeline()
            .to(boxes?.[0], { x: 120, rotation: 360 })
            .to(boxes?.[1], { x: -120, rotation: -360 }, '<')
            .to(boxes?.[2], { y: -166 })
            .reverse();
    }, { scope: container });
    return (_jsx("main", { children: _jsxs("section", { className: "boxes-container", ref: container, children: [_jsx("h2", { children: "Use the button to toggle a Timeline" }), _jsx("div", { children: _jsx("button", { className: "gsap-button", onClick: toggleTimeline, children: "Toggle Timeline" }) }), _jsx("div", { className: "box gradient-blue", children: "Box 1" }), _jsx("div", { className: "box gradient-blue", children: "Box 2" }), _jsx("div", { className: "box gradient-blue", children: "Box 3" })] }) }));
}
