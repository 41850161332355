import { jsx as _jsx } from "react/jsx-runtime";
import TypewriterEffect from 'typewriter-effect';
export const Typewriter = ({ strings, options = {}, content, delay = 250, onInit, className, id }) => {
    return (_jsx("div", { className: className, id: `type-writer-${id}`, children: _jsx(TypewriterEffect, { options: {
                strings: strings,
                autoStart: true,
                loop: true,
                ...options
            }, onInit: onInit || ((typewriter) => {
                typewriter.typeString(content || "")
                    .callFunction(() => { })
                    .pauseFor(delay)
                    // .deleteAll()
                    // .callFunction(() => { })
                    .start();
            }) }) }));
};
