import { API } from "./API";
import { apiUrl, downloadUrl, saveAs } from "./utils";
export function createApi(config) {
    const apiClient = new API(config.apiEndpoint, config.options?.axiosConfig);
    const token = config.options?.getAccessToken?.();
    if (token) {
        apiClient.setAccessToken(() => token, config?.options?.tokenMethod);
    }
    return {
        get client() {
            return apiClient;
        },
        downloadUrl(path, params) {
            return downloadUrl(path, params);
        },
        async download(path, params) {
            const url = downloadUrl(path, params);
            return apiClient.get(url, { responseType: "blob" }).then((res) => {
                const [path, query] = url.split("?");
                const lastSegment = path?.split("/").pop();
                const fileFormat = new URLSearchParams(query).get("format");
                const contentDisposition = res.headers["content-disposition"] ||
                    res.headers["Content-Disposition"];
                const filename = contentDisposition
                    ? contentDisposition.split("filename=")[1]
                    : lastSegment
                        ? `${lastSegment}.${fileFormat}`
                        : `download.${fileFormat}`;
                filename && saveAs(res.data, filename);
            });
        },
        async get(path, params) {
            const url = apiUrl(path, params);
            return apiClient.get(url);
        },
        async post(path, params, data) {
            const url = apiUrl(path, params);
            return apiClient.post(url, data);
        },
        async put(path, params, data) {
            const url = apiUrl(path, params);
            return apiClient.put(url, data);
        },
        async delete(path, params) {
            const url = apiUrl(path, params);
            return apiClient.delete(url);
        },
        async patch(path, params, data) {
            const url = apiUrl(path, params);
            return apiClient.patch(url, data);
        },
    };
}
