let defaultNumberOptions = {};
export const setNumberOptions = (options) => {
    defaultNumberOptions = options;
};
const intlFormat = (value, options = defaultNumberOptions) => {
    return new Intl.NumberFormat(value.locale, options).format(value);
};
export const formatNumber = (value, options = defaultNumberOptions) => {
    return intlFormat(value, options);
};
export const formatToUnit = (value, unit, display = "long") => {
    return intlFormat(value, {
        style: "unit",
        unit,
        unitDisplay: display,
    });
};
export const formatToCurrency = (value, currency, options = {}) => {
    const { currencySign = "standard", currencyDisplay = "symbol" } = options;
    return intlFormat(value, {
        style: "currency",
        currency,
        currencySign,
        currencyDisplay,
    });
};
export const isNumberString = (value) => {
    return !isNaN(+value);
};
export const isInt = (value) => {
    return value % 1 === 0;
};
export const isFloat = (value) => {
    return value % 1 !== 0;
};
Number.prototype.format = function (options = defaultNumberOptions) {
    return formatNumber(this.valueOf(), options);
};
Number.prototype.formatCurrency = function (currency, options) {
    return formatToCurrency(this.valueOf(), currency, options);
};
Number.prototype.formatUnit = function (unit, display = "long") {
    return formatToUnit(this.valueOf(), unit, display);
};
const billion = 1000000000;
const million = 1000000;
export const formatMoneyNumber = (amount, isShort) => {
    // Create a number formatter instance
    const formatter = new Intl.NumberFormat('vi-VN', {
        // style: 'currency',
        currency: 'VND', // Change currency as needed
        minimumFractionDigits: 0,
    });
    let usingAmount = amount;
    if (isShort) {
        if (amount > billion) {
            usingAmount = amount / billion;
        }
        else if (amount > million) {
            usingAmount = amount / million;
        }
    }
    let amountString = formatter.format(usingAmount);
    // Format the amount as currency
    return `${amountString} ${isShort ? amount >= billion ? "tỷ " : amount >= million ? "triệu" : "" : ""}`;
};
export const formatNumberWithCommas = (number) => {
    // Convert the number to a string
    const numberStr = number.toString();
    // Reverse the string to make it easier to insert commas
    const reversedNumberStr = numberStr.split('').reverse().join('');
    // Insert commas every three characters
    const formattedReversedNumberStr = reversedNumberStr.match(/.{1,3}/g)?.join(',') || '';
    // Reverse the string back to its original order
    const formattedNumberStr = formattedReversedNumberStr.split('').reverse().join('');
    return formattedNumberStr;
};
