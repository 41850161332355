import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { motion, useAnimation } from 'framer-motion';
export const ClickerAnimate = ({ width, height, children, flyUp, fallDown, length = 250, isIOS }) => {
    const touchAreaRef = useRef();
    const controls = useAnimation();
    const [flyUpAnimations, setFlyUpAnimations] = useState([]);
    const [fallDownAnimations, setFallDownAnimations] = useState([]);
    const [animationId, setAnimationId] = useState(0);
    const handleClick = () => {
        if (touchAreaRef.current) {
            controls.start({
                scale: 1.02,
                transition: { type: 'spring', stiffness: 50 }
            }).then(() => {
                controls.start({
                    scale: 1,
                    transition: { type: 'spring', stiffness: 50 }
                });
            });
            const newAnimationId = animationId + 1;
            setFlyUpAnimations(prev => [...prev, { id: newAnimationId }]);
            setFallDownAnimations(prev => [...prev, { id: newAnimationId }]);
            setAnimationId(newAnimationId);
        }
    };
    return (_jsxs(motion.div, { ref: touchAreaRef, animate: controls, style: {
            width,
            height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            cursor: 'pointer',
            overflow: 'visible'
        }, onClick: isIOS ? () => { } : handleClick, onTouchStart: isIOS ? handleClick : () => { }, children: [children, flyUp && !isIOS && flyUpAnimations.map(({ id }) => (_jsx(motion.div, { initial: { opacity: 1, y: -1 * height / 2 }, animate: { opacity: 0, y: -1 * length }, transition: { duration: 1 }, style: {
                    position: 'absolute',
                    color: 'red',
                    fontWeight: 'bold',
                    pointerEvents: 'none'
                }, onAnimationComplete: () => {
                    setFlyUpAnimations(prev => prev.filter(animation => animation.id !== id));
                }, children: flyUp }, id))), fallDown && !isIOS && fallDownAnimations.map(({ id }) => (_jsx(motion.div, { initial: { opacity: 1, y: height / 2 }, animate: { opacity: 0, y: length }, transition: { duration: 1 }, style: {
                    position: 'absolute',
                    color: 'red',
                    fontWeight: 'bold',
                    pointerEvents: 'none'
                }, onAnimationComplete: () => {
                    setFallDownAnimations(prev => prev.filter(animation => animation.id !== id));
                }, children: fallDown }, id)))] }));
};
