export var HttpErrorCode;
(function (HttpErrorCode) {
    HttpErrorCode[HttpErrorCode["BadRequest"] = 400] = "BadRequest";
    HttpErrorCode[HttpErrorCode["Unauthorized"] = 401] = "Unauthorized";
    HttpErrorCode[HttpErrorCode["Forbidden"] = 403] = "Forbidden";
    HttpErrorCode[HttpErrorCode["NotFound"] = 404] = "NotFound";
    HttpErrorCode[HttpErrorCode["MethodNotAllowed"] = 405] = "MethodNotAllowed";
    HttpErrorCode[HttpErrorCode["NotAcceptable"] = 406] = "NotAcceptable";
    HttpErrorCode[HttpErrorCode["RequestTimeout"] = 408] = "RequestTimeout";
    HttpErrorCode[HttpErrorCode["Conflict"] = 409] = "Conflict";
    HttpErrorCode[HttpErrorCode["Gone"] = 410] = "Gone";
    HttpErrorCode[HttpErrorCode["LengthRequired"] = 411] = "LengthRequired";
    HttpErrorCode[HttpErrorCode["PreconditionFailed"] = 412] = "PreconditionFailed";
    HttpErrorCode[HttpErrorCode["PayloadTooLarge"] = 413] = "PayloadTooLarge";
    HttpErrorCode[HttpErrorCode["URITooLong"] = 414] = "URITooLong";
    HttpErrorCode[HttpErrorCode["UnsupportedMediaType"] = 415] = "UnsupportedMediaType";
    HttpErrorCode[HttpErrorCode["RangeNotSatisfiable"] = 416] = "RangeNotSatisfiable";
    HttpErrorCode[HttpErrorCode["ExpectationFailed"] = 417] = "ExpectationFailed";
    HttpErrorCode[HttpErrorCode["ImATeapot"] = 418] = "ImATeapot";
    HttpErrorCode[HttpErrorCode["MisdirectedRequest"] = 421] = "MisdirectedRequest";
    HttpErrorCode[HttpErrorCode["UnprocessableEntity"] = 422] = "UnprocessableEntity";
    HttpErrorCode[HttpErrorCode["Locked"] = 423] = "Locked";
    HttpErrorCode[HttpErrorCode["FailedDependency"] = 424] = "FailedDependency";
    HttpErrorCode[HttpErrorCode["UnorderedCollection"] = 425] = "UnorderedCollection";
    HttpErrorCode[HttpErrorCode["UpgradeRequired"] = 426] = "UpgradeRequired";
    HttpErrorCode[HttpErrorCode["PreconditionRequired"] = 428] = "PreconditionRequired";
    HttpErrorCode[HttpErrorCode["TooManyRequests"] = 429] = "TooManyRequests";
    HttpErrorCode[HttpErrorCode["RequestHeaderFieldsTooLarge"] = 431] = "RequestHeaderFieldsTooLarge";
    HttpErrorCode[HttpErrorCode["UnavailableForLegalReasons"] = 451] = "UnavailableForLegalReasons";
    HttpErrorCode[HttpErrorCode["InternalServerError"] = 500] = "InternalServerError";
    HttpErrorCode[HttpErrorCode["NotImplemented"] = 501] = "NotImplemented";
    HttpErrorCode[HttpErrorCode["BadGateway"] = 502] = "BadGateway";
    HttpErrorCode[HttpErrorCode["ServiceUnavailable"] = 503] = "ServiceUnavailable";
    HttpErrorCode[HttpErrorCode["GatewayTimeout"] = 504] = "GatewayTimeout";
    HttpErrorCode[HttpErrorCode["HTTPVersionNotSupported"] = 505] = "HTTPVersionNotSupported";
    HttpErrorCode[HttpErrorCode["VariantAlsoNegotiates"] = 506] = "VariantAlsoNegotiates";
    HttpErrorCode[HttpErrorCode["InsufficientStorage"] = 507] = "InsufficientStorage";
    HttpErrorCode[HttpErrorCode["LoopDetected"] = 508] = "LoopDetected";
    HttpErrorCode[HttpErrorCode["BandwidthLimitExceeded"] = 509] = "BandwidthLimitExceeded";
    HttpErrorCode[HttpErrorCode["NotExtended"] = 510] = "NotExtended";
    HttpErrorCode[HttpErrorCode["NetworkAuthenticationRequired"] = 511] = "NetworkAuthenticationRequired";
})(HttpErrorCode || (HttpErrorCode = {}));
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["Multipart"] = "multipart/form-data";
    ContentType["FormUrlEncoded"] = "application/x-www-form-urlencoded";
})(ContentType || (ContentType = {}));
export var TokenMethod;
(function (TokenMethod) {
    TokenMethod["Bearer"] = "Bearer";
    TokenMethod["Basic"] = "Basic";
    TokenMethod["Digest"] = "Digest";
    TokenMethod["ApiKey"] = "ApiKey";
})(TokenMethod || (TokenMethod = {}));
export const contentTypeKey = "Content-Type";
export const authorizationKey = "Authorization";
