import axios, { isAxiosError, } from "axios";
import { TokenMethod, authorizationKey } from "./consts";
export class API {
    baseUrl;
    config;
    client;
    abortControllers = new Map();
    errorHandlers = new Map();
    constructor(baseUrl, config = {}) {
        this.baseUrl = baseUrl;
        this.config = config;
        this.client = axios.create({
            ...config,
            baseURL: baseUrl,
        });
    }
    createAbortSignal = (cancelToken) => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }
        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };
    abort = (cancelToken) => {
        const controller = this.abortControllers.get(cancelToken);
        if (controller) {
            controller.abort();
            this.abortControllers.delete(cancelToken);
        }
    };
    async request(config) {
        const source = axios.CancelToken.source();
        const signalKey = source.token;
        const signal = this.createAbortSignal(signalKey);
        const result = await this.client.request({
            ...config,
            signal,
        });
        return {
            data: result.data,
            abort: () => this.abort(signalKey),
            headers: result.headers,
        };
    }
    get(url, config) {
        return this.request({ ...config, url, method: "GET" });
    }
    post(url, data, config) {
        return this.request({ ...config, url, data, method: "POST" });
    }
    put(url, data, config) {
        return this.request({ ...config, url, data, method: "PUT" });
    }
    patch(url, data, config) {
        return this.request({ ...config, url, data, method: "PATCH" });
    }
    delete(url, config) {
        return this.request({ ...config, url, method: "DELETE" });
    }
    setAccessToken(getter, tokenMethod = TokenMethod.Bearer) {
        this.client.interceptors.request.use((config) => {
            const token = `${tokenMethod} ${getter()}`;
            config.headers[authorizationKey] = token;
            return config;
        });
    }
    setErrorHandler(code, handler) {
        this.errorHandlers.set(code, handler);
    }
    async refreshAccessToken() {
        try {
            const response = await axios.post(`${this.baseUrl}/auth/refresh-token`, {}, {
                withCredentials: true, // Ensure cookies are sent
            });
            return response.data.accessToken; // Adjust based on your response structure
        }
        catch (error) {
            console.error("Failed to refresh access token:", error);
            return null; // Handle the error appropriately
        }
    }
    onError(errorsHandler) {
        this.client.interceptors.response.use(undefined, async (error) => {
            if (!isAxiosError(error)) {
                return errorsHandler(error);
            }
            const { response } = error;
            if (response) {
                const handler = this.errorHandlers.get(response.status);
                const retry = async () => {
                    // If unauthorized, attempt to refresh the token
                    if (response.status === 401) {
                        const newAccessToken = await this.refreshAccessToken();
                        if (newAccessToken) {
                            // Update the token in the config and retry the original request
                            response.config.headers[authorizationKey] = `${TokenMethod.Bearer} ${newAccessToken}`;
                            return this.client.request(response.config);
                        }
                    }
                    return this.client.request(response.config);
                };
                if (handler) {
                    return handler(response, retry);
                }
                return errorsHandler(response, retry);
            }
            return errorsHandler(error);
        });
    }
}
