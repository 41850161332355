import { jsx as _jsx } from "react/jsx-runtime";
import { createStore } from "@core-ui/react-mobx-state";
import { AuthStore } from "./AuthStore";
import { NotifyStore } from "./NotifyStore";
import { useLayoutEffect } from "react";
import AppcenterSDK from "@core-sdk/app-center";
export class AuthenticationStore {
    authStore;
    notiStore;
    constructor() {
        this.authStore = new AuthStore();
        this.notiStore = new NotifyStore();
    }
}
export const authenticationStore = createStore(new AuthenticationStore());
export const useAuthenticationStore = authenticationStore.useStore;
const Provider = authenticationStore.Provider;
export const AuthProvider = ({ children, config }) => {
    useLayoutEffect(() => {
        if (config) {
            AppcenterSDK.getInstance(config.apiConfig);
        }
    }, [config]);
    return _jsx(Provider, { children: children });
};
export * from "./NotifyStore";
export * from "./AuthStore";
