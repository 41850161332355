import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TimelineMax, Linear, Power1 } from "gsap";
import { useEffect } from 'react';
import "./glowingJump.style.css";
export const GlowingJump = ({ id }) => {
    useEffect(() => {
        if (!id)
            return;
        /**
         * Set the filter property for an element, accounting for both
         * 'webkitFilter' and 'filter'
         *
         * example: setFilter('url("/svg/filters/gooey-effects.svg#goo")', menuContainerElem);
         */
        let setFilter = function setFilter(path, elem) {
            elem.style.filter = path;
            elem.style.webkitFilter = path;
        };
        // let loaderContainer = document.querySelector(`#${id}.anim-container`),
        let loaderSVG = document.querySelector(`#${id} .loader`), circleL = document.querySelector(`#${id} .circleL`), circleR = document.querySelector(`#${id} .circleR`), jumpArc = document.querySelector(`#${id} .jump`), BASE_DURATION_MULTIPLIER = 1.0;
        if (!jumpArc)
            return;
        let jumpArcReflection = jumpArc.cloneNode();
        jumpArcReflection.setAttribute('class', 'reflection'); // setAttribute needs to be used for classing SVG in JS
        loaderSVG.appendChild(jumpArcReflection);
        setFilter('url("#strokeGlow")', jumpArc);
        setFilter('url("#strokeGlow")', jumpArcReflection);
        let masterTL = new TimelineMax({
            repeat: -1,
        });
        function jump() {
            let jumpTL = new TimelineMax();
            jumpTL
                .set([jumpArc, jumpArcReflection], {
                drawSVG: '0% 0%',
            })
                .set([circleL, circleR], {
                attr: {
                    rx: 0,
                    ry: 0,
                },
            })
                .to([jumpArc, jumpArcReflection], BASE_DURATION_MULTIPLIER * 0.4, {
                drawSVG: '0% 30%',
                ease: Linear.easeNone,
            })
                // scale up the ripple ovals (with x scaling a bit more since, you know, it's a horizontal oval :-) )
                .to(circleL, BASE_DURATION_MULTIPLIER * 2, {
                attr: {
                    rx: '+=30',
                    ry: '+=10',
                },
                opacity: 0, // ripple, then fade out
                ease: Power1.easeOut,
            }, '-=0.1')
                .to([jumpArc, jumpArcReflection], BASE_DURATION_MULTIPLIER * 1.0, {
                drawSVG: '50% 80%',
                ease: Linear.easeNone,
            }, '-=1.9')
                .to([jumpArc, jumpArcReflection], BASE_DURATION_MULTIPLIER * 0.7, {
                drawSVG: '100% 100%',
                ease: Linear.easeNone,
            }, '-=0.9')
                // finish by animating the right circle ripple
                .to(circleR, BASE_DURATION_MULTIPLIER * 2, {
                attr: {
                    rx: '+=30',
                    ry: '+=10',
                },
                opacity: 0, // ripple, then fade out
                ease: Power1.easeOut,
            }, '-=0.5');
            jumpTL.timeScale(3);
            return jumpTL;
        }
        masterTL.add(jump());
    }, [id]);
    return (_jsx("div", { id: id, className: 'anim-container absolute bg-transparent z-50', children: _jsxs("div", { className: 'loader-container absolute top-[50%] left-[50%] ml-[-125px] mt[-100px]', children: [_jsx("svg", { className: 'w-0 h-0 absolute', children: _jsx("defs", { children: _jsxs("filter", { id: 'strokeGlow', y: '-10', x: '-10', width: '250', height: '150', children: [_jsx("feOffset", { in: 'StrokePaint', dx: '0', dy: '0', result: 'centeredOffset' }), _jsx("feGaussianBlur", { in: 'centeredOffset', stdDeviation: '2', result: 'blur1' }), _jsx("feGaussianBlur", { in: 'centeredOffset', stdDeviation: '5', result: 'blur2' }), _jsx("feGaussianBlur", { in: 'centeredOffset', stdDeviation: '15', result: 'blur3' }), _jsxs("feMerge", { children: [_jsx("feMergeNode", { in: 'blur1' }), _jsx("feMergeNode", { in: 'blur2' }), _jsx("feMergeNode", { in: 'blur3' }), _jsx("feMergeNode", { in: 'SourceGraphic' })] })] }) }) }), _jsxs("svg", { className: 'loader', xmlns: 'http://www.w3.org/2000/svg', width: '250px', height: '200px', viewBox: '0 0 250 200', children: [_jsx("svg", { style: { overflow: 'visible' }, children: _jsx("path", { className: 'jump', fill: 'none', stroke: '#33FFFF', strokeWidth: '10', "stroke-linecap": 'round', strokeMiterlimit: '10', d: 'M55.5 98.5c0-35.3 31.3-64 70-64s70 28.7 70 64' }) }), _jsxs("g", { fill: 'none', strokeWidth: '1', stroke: '#33FFFF', strokeLinecap: 'round', strokeMiterlimit: '10', children: [_jsx("ellipse", { className: 'circleL', cx: '55.2', cy: '102.5', rx: '21.7', ry: '5.5' }), _jsx("ellipse", { className: 'circleR', cx: '195.2', cy: '103.5', rx: '21.7', ry: '5.5' })] })] })] }) }));
};
