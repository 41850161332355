import { useEffect } from 'react';
export const useDragableElement = (id) => {
    const getElementMoving = () => document.querySelector(`#${id}.moving`);
    const getElement = () => document.querySelector(`#${id}`);
    const handleMouseMove = ({ clientX, clientY }) => {
        const container = getElementMoving();
        if (!container)
            return;
        container.style.left = `${clientX}px`;
        container.style.top = `${clientY}px`;
    };
    const onDrop = () => {
        const container = getElementMoving();
        if (!container)
            return;
        container.classList.remove('moving');
    };
    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('click', onDrop);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('click', onDrop);
        };
    }, []);
    const onSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const container = getElement();
        if (!container)
            return;
        container.classList.add('moving');
    };
    return { onSelect };
};
