import { jsx as _jsx } from "react/jsx-runtime";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
export const ExpandDown = ({ children, startOnMount = true, stiffness = 250, duration = 0.5, delay = 0, slideEffect = true, width, height, style = {} }) => {
    const wrapperRef = useRef();
    const hasAnimated = useRef(false);
    const controls = useAnimation();
    useEffect(() => {
        if (wrapperRef.current && startOnMount && !hasAnimated.current) {
            const elementHeight = wrapperRef.current.scrollHeight;
            if (slideEffect) {
                controls.start({
                    opacity: 1,
                    transform: "translateY(0%)",
                    transition: { stiffness, duration, delay },
                });
            }
            else {
                controls.start({
                    opacity: 1,
                    height: elementHeight,
                    transition: { stiffness, duration, delay },
                });
            }
            hasAnimated.current = true;
        }
    }, [wrapperRef.current, startOnMount, slideEffect]);
    if (slideEffect) {
        return (_jsx("div", { style: {
                width: "100%",
                height: "100%",
                overflow: "hidden",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                ...style
            }, children: _jsx(motion.div, { ref: wrapperRef, animate: controls, initial: { transform: "translateY(-100%)" }, style: {
                    width: "100%",
                    height: "100%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    ...style
                }, children: children }) }));
    }
    return _jsx(motion.div, { ref: wrapperRef, animate: controls, initial: { height: 0, opacity: 0 }, style: {
            width: "100%",
            height: "100%",
            display: 'flex',
            ...style
        }, children: children });
};
