import urlcat from "urlcat";
export const saveAs = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
};
export const apiUrl = (baseTemplate, params) => {
    const url = baseTemplate.replace(/{([a-zA-Z0-9_-]+)}/g, ":$1");
    return urlcat(url, params);
};
export const downloadUrl = (path, params) => {
    return apiUrl(path, { format: "csv", ...params });
};
