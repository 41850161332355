import { jsx as _jsx } from "react/jsx-runtime";
import { createStore } from "@core-ui/react-mobx-state";
import { UserStore } from "./UserStore";
import { RoleStore } from "./RoleStore";
import { NotifyStore } from "./NotifyStore";
import { AppcenterSDK } from "@core-sdk/app-center";
import { useLayoutEffect } from "react";
import { PermissionStore } from "./PermissionStore";
export class UMSStore {
    userStore;
    roleStore;
    notiStore;
    permissionStore;
    constructor() {
        this.userStore = new UserStore();
        this.roleStore = new RoleStore();
        this.notiStore = new NotifyStore();
        this.permissionStore = new PermissionStore();
    }
}
export const uMSStore = createStore(new UMSStore());
export const useUMSStore = uMSStore.useStore;
const Provider = uMSStore.Provider;
export const UMSProvider = ({ children, config }) => {
    useLayoutEffect(() => {
        if (config) {
            AppcenterSDK.getInstance(config.apiConfig);
        }
    }, [config]);
    return _jsx(Provider, { children: children });
};
export * from "./UserStore";
export * from "./RoleStore";
export * from "./PermissionStore";
export * from "./NotifyStore";
