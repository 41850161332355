import { BaseService } from "@core-ui/api-client";
export class ChapterService extends BaseService {
    constructor(api) {
        super(api);
    }
    create(createData) {
        return this.api.post("/chapters", {}, createData);
    }
    update(id, updateData) {
        return this.api.put("/chapters/{chapterId}", { chapterId: id }, updateData);
    }
    patchUpdate(chapterId, updateData) {
        return this.api.patch("/chapters/{chapterId}", { chapterId }, updateData);
    }
    getOne(id) {
        return this.api.get("/chapters/{chapterId}", { chapterId: id });
    }
    getMany(filter) {
        return this.api.get("/chapters", filter);
    }
    getAll(filter) {
        return this.api.get("/chapters/all", filter);
    }
    delete(id) {
        return this.api.delete("/chapters/{chapterId}", { chapterId: id });
    }
}
