import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import "./waterReflection.style.css";
export const WaterReflection = ({ bgImage, ratio = 25, bgStyle = {} }) => {
    useEffect(() => {
        const bg = document.querySelector("#bg");
        const base = document.querySelector("#base");
        const reflect = document.querySelector("#reflect");
        const disp = document.querySelector("#result");
        let flg, flg_now;
        const root = document.documentElement;
        if (!disp || !base || !bg)
            return;
        flg = flg_now;
        disp.innerHTML = `${ratio}`;
        let css_ratio = 100 - ratio;
        root.style.setProperty("--ratio", css_ratio + "%");
        flg_now = true;
        base.style.setProperty("background-position", "top calc(50% + ( var(--ratio) - 50% ) * 2) center");
        reflect.style.setProperty("background-position", "center");
        if (flg != flg_now) {
            bg.style.setProperty("transform", "scale3d(1, -1, 1)");
        }
    }, [ratio, bgImage]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { id: "bg" }), _jsx("div", { id: "reflect", style: {
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "100% 100%",
                    ...bgStyle,
                } }), _jsx("div", { id: "base" }), _jsx("div", { className: "main-reflect", children: _jsx("section", { className: "section-reflect", children: _jsx("div", {}) }) }), _jsx("svg", { className: "reflect-svg", children: _jsxs("filter", { id: "filter", filterUnits: "objectBoundingBox", x: "0", y: "0", children: [_jsx("feTurbulence", { id: "feturbulence", type: "fractalNoise", baseFrequency: "0.01 0.1", numOctaves: "10", seed: "1" }), _jsx("feDisplacementMap", { xChannelSelector: "B", yChannelSelector: "G", scale: "100", in: "SourceGraphic", result: "result", children: _jsx("animate", { attributeName: "scale", dur: "5s", values: "100;-100;100", repeatCount: "indefinite" }) })] }) })] }));
};
