import { BaseStore, makeObservable, observable } from "@core-ui/react-mobx-state";
export class JobStore extends BaseStore {
    error = null;
    jobs;
    job;
    filterData;
    loginData = {};
    loading = false;
    savedJobs;
    selectedJobs;
    pagingFilterData;
    constructor() {
        super();
        makeObservable(this, {
            error: observable,
            jobs: observable,
            loading: observable,
            loginData: observable,
            job: observable,
            filterData: observable,
            savedJobs: observable,
            selectedJobs: observable,
            pagingFilterData: observable,
        });
    }
}
