import { BaseSDK } from "@core-sdk/core";
import { NovelCategoryService, RecruiterCategoryService, ChapterService, NovelService, CommentService, AuthorService, RoleService, UserService, JobService, PermissionService, CrawlerService, AuthService } from "./services";
export class AppcenterSDK extends BaseSDK {
    static instance = null;
    userService;
    novelCategoryService;
    recruiterCategoryService;
    roleService;
    permissionService;
    jobService;
    authorService;
    commentService;
    novelService;
    chapterService;
    crawlerService;
    authService;
    constructor(config) {
        super(config);
        this.userService = new UserService(this.api);
        this.novelCategoryService = new NovelCategoryService(this.api);
        this.recruiterCategoryService = new RecruiterCategoryService(this.api);
        this.roleService = new RoleService(this.api);
        this.permissionService = new PermissionService(this.api);
        this.jobService = new JobService(this.api);
        this.authorService = new AuthorService(this.api);
        this.commentService = new CommentService(this.api);
        this.novelService = new NovelService(this.api);
        this.chapterService = new ChapterService(this.api);
        this.crawlerService = new CrawlerService(this.api);
        this.authService = new AuthService(this.api);
    }
    static getInstance = (config) => {
        if (!this.instance) {
            this.instance = new AppcenterSDK(config || { apiEndpoint: "no-api-end-point" });
        }
        return this.instance;
    };
    getUserControl() {
        return {
            ...this.getBaseControl(this.userService),
        };
    }
    getRoleControl() {
        return {
            ...this.getBaseControl(this.roleService),
        };
    }
    getPermissionControl() {
        return {
            ...this.getBaseControl(this.permissionService),
        };
    }
    getRecruiterCategoryControl() {
        return {
            ...this.getBaseControl(this.recruiterCategoryService),
        };
    }
    getJobControl() {
        return {
            ...this.getBaseControl(this.jobService),
        };
    }
    getAuthorControl() {
        return {
            ...this.getBaseControl(this.authorService),
        };
    }
    getNovelsCategoryControl() {
        return {
            ...this.getBaseControl(this.novelCategoryService),
        };
    }
    getNovelControl() {
        return {
            ...this.getBaseControl(this.novelService),
        };
    }
    getChapterControl() {
        return {
            ...this.getBaseControl(this.chapterService),
        };
    }
    getCommentControl() {
        return {
            ...this.getBaseControl(this.commentService),
        };
    }
    addAnydatJobData(jobId, categoryId, htmlString) {
        return this.crawlerService.addAnydayJobData(jobId, categoryId, htmlString);
    }
    login(data) {
        return this.authService.login(data);
    }
    logout() {
        return this.authService.logout();
    }
    resetPassword(data) {
        return this.authService.resetPassword(data);
    }
    register(data) {
        return this.authService.register(data);
    }
    refreshToken() {
        return this.authService.refreshToken();
    }
    validateToken() {
        return this.authService.validateToken();
    }
}
export * from "./types";
export default AppcenterSDK;
