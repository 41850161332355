import { BaseStore, makeObservable, observable } from "@core-ui/react-mobx-state";
export class RoleStore extends BaseStore {
    error = null;
    roles;
    role;
    filterData;
    loginData = {};
    loading = false;
    constructor() {
        super();
        makeObservable(this, {
            error: observable,
            roles: observable,
            loading: observable,
            loginData: observable,
            role: observable,
            filterData: observable,
        });
    }
}
