export const hasOwn = (object, key) => {
    return object != null && Object.hasOwnProperty.call(object, key);
};
export function getVal(obj, key, defaultValue) {
    const keys = key.split(".");
    const val = keys.reduce((acc, key) => acc?.[key], obj);
    return val === undefined ? defaultValue : val;
}
export const setVal = (obj, key, value) => {
    const keys = key.split(".");
    const lastKey = keys.pop();
    const target = keys.reduce((acc, key) => acc?.[key], obj);
    target[lastKey] = value;
    return obj;
};
export const isObject = (value) => {
    return Object(value) !== value;
};
export const flatten = (obj) => {
    const result = {};
    const recurse = (cur, prop) => {
        if (!isObject(cur) || Array.isArray(cur) || cur instanceof Blob) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            result[prop] = cur;
        }
        else {
            let isEmpty = true;
            for (const p in cur) {
                isEmpty = false;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                recurse(cur[p], prop ? prop + "." + p : p);
            }
            if (isEmpty && prop) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                result[prop] = {};
            }
        }
    };
    recurse(obj, "");
    return result;
};
// check if value is a class
export const isClass = (value) => {
    return typeof value === "function" && /^\s*class\s+/.test(String(value));
};
export const isPlainObject = (value) => {
    return (value !== null &&
        typeof value === "object" &&
        Object.getPrototypeOf(value) === Object.prototype);
};
export const isInstance = (value) => {
    return typeof value === "object" && !isPlainObject(value) && !isClass(value);
};
export const isPrimitiveType = (value) => {
    return (typeof value === "function" &&
        ["String", "Number", "Boolean", "Symbol", "Array"].includes(value.name));
};
export const arrayToRecord = (arr) => {
    return arr.reduce((acc, curr) => {
        const prop = curr.name;
        acc[String(prop)] = curr;
        return acc;
    }, {});
};
export const toPlainObject = (obj) => {
    return Object.assign({}, obj);
};
