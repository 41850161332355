import { useEffect, useRef } from "react";
export const useInterval = (callback, interval) => {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        const handler = savedCallback.current;
        if (handler) {
            const id = setInterval(handler, interval);
            return () => clearInterval(id);
        }
        return;
    }, [interval]);
};
