import { BaseService } from "@core-ui/api-client";
export class CrawlerService extends BaseService {
    constructor(api) {
        super(api);
    }
    create = (createData) => {
        throw new Error("function not implemented");
    };
    update = (id, updateData) => {
        throw new Error("function not implemented");
    };
    patchUpdate = (id, updateData) => {
        throw new Error("function not implemented");
    };
    getOne = (id) => {
        throw new Error("function not implemented");
    };
    getMany = (filter) => {
        throw new Error("function not implemented");
    };
    delete = (id) => {
        throw new Error("function not implemented");
    };
    addAnydayJobData(jobId, categoryId, htmlString) {
        return this.api.post("/crawler/anidays", {}, {
            jobId,
            categoryId,
            htmlString
        });
    }
    addNovelsFromTTV(jobId, categoryId, htmlString) {
        return this.api.post("/crawler/tangthuvien", {
            jobId,
            categoryId,
            htmlString
        });
    }
}
