import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { MeteorRain } from "./MeteorRain";
export const MeteorMouseEffect = ({ id, style = {} }) => {
    useEffect(() => {
        const canvas = document.getElementById(`meteor-mouse-${id}`);
        if (canvas) {
            new MeteorRain(canvas);
        }
    }, [id]);
    return _jsx("canvas", { id: `meteor-mouse-${id}`, className: "meteor-mouse-animation", style: {
            ...style
        } });
};
