import { jsx as _jsx } from "react/jsx-runtime";
import { createStore } from "@core-ui/react-mobx-state";
import { CategoryStore } from "./CategoryStore";
import { NotifyStore } from "./NotifyStore";
import AppcenterSDK from "@core-sdk/app-center";
import { useLayoutEffect } from "react";
import { JobStore } from "./JobStore";
export class RecruiterStore {
    categoryStore;
    notiStore;
    jobStore;
    constructor() {
        this.categoryStore = new CategoryStore();
        this.notiStore = new NotifyStore();
        this.jobStore = new JobStore();
    }
}
export const recruiterStore = createStore(new RecruiterStore());
export const useRecruiterStore = recruiterStore.useStore;
const Provider = recruiterStore.Provider;
export const RecruiterProvider = ({ children, config }) => {
    useLayoutEffect(() => {
        if (config) {
            AppcenterSDK.getInstance(config.apiConfig);
        }
    }, [config]);
    return _jsx(Provider, { children: children });
};
export * from "./CategoryStore";
export * from "./NotifyStore";
export * from "./JobStore";
