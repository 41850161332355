import { BaseStore, makeObservable, observable } from "@core-ui/react-mobx-state";
export class NotifyStore extends BaseStore {
    messageQueue = [];
    constructor() {
        super();
        makeObservable(this, {
            messageQueue: observable,
        });
    }
}
