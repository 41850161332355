import { jsx as _jsx } from "react/jsx-runtime";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
export const FadeAppear = ({ children, startOnMount = true, stiffness = 250, duration = 0.5, delay = 0, slideEffect = true, width, height, style = {} }) => {
    const wrapperRef = useRef();
    const controls = useAnimation();
    const hasAnimated = useRef(false);
    useEffect(() => {
        if (wrapperRef.current && startOnMount && !hasAnimated.current) {
            controls.start({
                opacity: 1,
                transition: { stiffness, duration, delay },
            });
        }
    }, [wrapperRef.current, startOnMount]);
    return _jsx(motion.div, { ref: wrapperRef, animate: controls, initial: { opacity: 0 }, style: {
            width: "100%",
            height: "100%",
            display: 'flex',
            justifyContent: "flex-start",
            alignItems: "flex-start",
            ...(style || {})
        }, children: children });
};
