import { BaseService } from "@core-ui/api-client";
export class AuthService extends BaseService {
    constructor(api) {
        super(api);
    }
    create = (createData) => {
        throw new Error("function not implemented");
    };
    update = (id, updateData) => {
        throw new Error("function not implemented");
    };
    patchUpdate = (id, updateData) => {
        throw new Error("function not implemented");
    };
    getOne = (id) => {
        throw new Error("function not implemented");
    };
    getMany = (filter) => {
        throw new Error("function not implemented");
    };
    delete = (id) => {
        throw new Error("function not implemented");
    };
    validateToken = () => {
        return this.api.get("/auth", {});
    };
    login = (data) => {
        return this.api.post("/auth/login", {}, data);
    };
    register = (data) => {
        return this.api.post("/auth/register", {}, data);
    };
    resetPassword = (data) => {
        return this.api.post("/auth/reset-password", {}, data);
    };
    logout = () => {
        return this.api.post("/auth/logout", {}, {});
    };
    refreshToken = () => {
        return this.api.post("/auth/refresh-token", {}, {});
    };
}
